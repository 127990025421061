import './App.css';
import { Routes, Route} from 'react-router-dom';
import AmountDate from './Pages/AmountDate';

function App() {
  return (
    <div>
     <Routes>
        <Route path="/" element={<AmountDate/>} />
        </Routes>
    </div>
  );
}

export default App;
